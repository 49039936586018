<template>
  <el-button :size="size" :type="button_type" @click="rollback">{{ text }}</el-button>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "backButton",
  props: {
    text: {
      type: String,
      default: '返回'
    },
    button_type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'large'
    }
  },
  methods: {
    ...mapActions("tabs", [
      "rollback",
    ])
  },
}
</script>

<style scoped>

</style>
