const MODULEKEY = process.env.VUE_APP_NAME
/**
    * 
    * @param {string} key 
    * @param {*} val 
    */
//将键值对,以 JSON 字符串的形式存储到sessionStorage
const setItem = function (key, val) {
    let setList = getStorage(MODULEKEY) || {}
    setList[key] = val
    sessionStorage.setItem(MODULEKEY, JSON.stringify(setList))
}
//sessionStorage 中获取指定键的值。
const getItem = function (key) {
    let getList = JSON.parse(sessionStorage.getItem(MODULEKEY)) || {}

    return getList[key]
}
//清空整个 sessionStorage
const removeAll = function () {
    sessionStorage.clear()
}
//从 sessionStorage 中删除指定键的数据
const removeItem = function (key) {
    let removeList = getStorage(MODULEKEY)
    delete removeList[key]
    sessionStorage.setItem(MODULEKEY, JSON.stringify(removeList))
}
//获取指定键对应的 sessionStorage 数据
const getStorage = function (key) {
    let getList = JSON.parse(sessionStorage.getItem(key))
    return getList
}
export {
    setItem,
    getItem,
    removeAll,
    removeItem
    
}