import login from "./modules/login";
import menu from "./modules/menu";
import userList from "./modules/user";
import role from "./modules/role";
import activity from "./modules/activity";
import notice from "./modules/notice";
import armory from "./modules/armory";
//引入运营api模块
import operate from "./modules/operate";
import match from "./modules/match/match";
//赛事专题
import match_subject from "./modules/match/match_subject";

import file from "./modules/file";
import download from "./modules/download";
import $http from "./http";
const getByUrl = (url, data) => {
  return $http({
    url: url,
    method: "get",
    data: data,
  });
};

const postByUrl = (url, data) => {
  return $http({
    url: url,
    method: "post",
    data,
  });
};

export default {
  getByUrl,
  postByUrl,
  ...login,
  ...menu,
  ...userList,
  ...role,
  ...activity,
  ...notice,
  ...file,

  armory: {
    ...armory,
  },
  operate,
  match: {
    ...match,
  },
  //赛事专题
  match_subject: {
    ...match_subject,
  },
  download,
};
