//查询列表
import $http from "../../http";

const s4_save_stage = (data) => {
  return $http({
    url: `/admin/new_match/stage/save_stage`,
    method: "post",
    data,
  });
};
export default{
  s4_save_stage
}
