// 递归添加路由
//根据给定的菜单列表(menuList)递归地生成路由配置(routes)
const addRoutes = (menuList = [], routes = []) => {
  console.log('菜单列表', menuList)
  for (let i in menuList) {
    //当前菜单项是否有子菜单 (menuList[i].children)，并且子菜单不为空且至少有一个子菜单项不被隐藏（!r.hide）。
    //如果条件满足，函数会递归调用自身，传入子菜单和当前的 routes 数组
    if (
      menuList[i].children &&
      menuList[i].children.length > 0 &&
      menuList[i].children.filter((r) => !r.hide).length > 0
    ) {
      addRoutes(menuList[i].children, routes)
    } 
    //果当前菜单项没有子菜单或子菜单项都被隐藏，那么函数会将当前菜单项转化为一个路由配置对象，
    //然后将该对象添加到 routes 数组中
    else {
      routes.push({
        meta: {
          reset: menuList[i].reset,
          keepAlive:
            !menuList[i].hide || menuList[i].url.indexOf('armory/') < 0,
        },
        name: menuList[i].name,
        path: menuList[i].url,
        // 路由文件写在url下，如/sys/user就在sys下创建user.vue文件
        component: () => import(`@/view/content${menuList[i].url}`),
      })
      //函数会再次检查当前菜单项是否有子菜单，并且再次递归调用自身，以处理子菜单。 
      if (menuList[i].children && menuList[i].children.length > 0) {
        addRoutes(menuList[i].children, routes)
      }
    }
  }
  return routes
}
export default addRoutes
