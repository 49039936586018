<template>
  <el-popover
      v-if="!perm || !perm.length || isPerm"
      :placement="placement"
      :width="width"
      v-model="visible">
    <p v-html="content" style="padding: 20px"></p>
    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="onCancelClick">取消</el-button>
      <el-button type="primary" size="mini" @click="onConfirmClick" style="margin-left: 20px">确定</el-button>
    </div>
    <el-button size="mini" :type="button_type" slot="reference">{{ text }}</el-button>
  </el-popover>
</template>

<script>
import hasPerm from "@/utils/hasPermSign";
import { mapState } from "vuex";
export default {
  name: "popconfirmButton",
  emits: ['onConfirm', 'onCancel'],
  components: {
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 220
    },
    placement: {
      type: String,
      default: 'top'
    },
    button_type: {
      type: String,
      default: 'default'
    },
    perm: {
      type: String
    }
  },
  computed: {
    ...mapState(["permSign"])
  },
  data() {
    return {
      visible: false,
      isPerm: false,
    };
  },
  mounted() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
    this.$bus.$on("onPermSignChanged", this.onPermSignChanged);
  },
  unmounted() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
  },
  destroyed() {
    this.$bus.$off("onPermSignChanged", this.onPermSignChanged);
  },
  watch: {
    //子组件props首次获取到父组件传来的值时，需要执行函数，此时就需要将immediate设为true
    "perm":{
      handler:function(val){
        if (!val || !val.length) {
          this.isPerm = true;
        } else {
          this.isPerm = this.hasPerm(val);
        }
      },
      immediate:true
    },
    "$store.state.permSign": {
      handler: function (val) {
        if (!this.perm || !this.perm.length) {
          this.isPerm = true;
        } else {
          this.isPerm = this.hasPerm(this.perm);
        }
      },
      immediate: true
    },
    "permSign": {
      handler: function (val) {
        if (!this.perm || !this.perm.length) {
          this.isPerm = true;
        } else {
          this.isPerm = this.hasPerm(this.perm);
        }
      },
      immediate: true
    }
  },
  methods: {
    onConfirmClick() {
      this.visible = false;
      this.$emit("onConfirm", {});
    },
    onCancelClick() {
      this.visible = false;
      this.$emit("onCancel");
    },
    hasPerm,
    onPermSignChanged() {
      if (!this.perm || !this.perm.length) {
        this.isPerm = true;
      } else {
        this.isPerm = this.hasPerm(this.perm);
      }
    }
  }
}
</script>

<style scoped>

</style>
