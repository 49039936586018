import $http from "../http";
//查询列表
const newsList = (data) => {
  return $http({
    url: "/admin/match/news_list",
    method: "get",
    data,
  });
};

const newsDetail = (data) => {
  return $http({
    url: "/admin/match/detail_news",
    method: "get",
    data,
  });
};

//添加&编辑
const saveNotice = (data) => {
  return $http({
    url: "/admin/match/save_news",
    method: "post",
    data,
  });
};

//添加&编辑
const saveNoticeAsDraft = (data) => {
  return $http({
    url: "/admin/activity/saveDraft",
    method: "post",
    data,
  });
};

//删除活动
const deleteActivity = (data) => {
  return $http({
    url: "/admin/activity/dellist",
    method: "post",
    data,
  });
};

const submissionSummary = (_id) => {
  return $http({
    url: "/admin/activity/summary_submission",
    method: "get",
    data: {
      _id: _id,
    },
  });
};

const detailSubmission = (data) => {
  return $http({
    url: "/admin/activity/detail_submission",
    method: "get",
    data,
  });
};

const isTop = (data) => {
  return $http({
    url: "/admin/match/update_news",
    method: "post",
    data,
  });
};

const acceptSubmission = (data) => {
  return $http({
    url: "/admin/activity/accept_submission",
    method: "post",
    data,
  });
};

const rejectSubmission = (data) => {
  return $http({
    url: "/admin/activity/reject_submission",
    method: "post",
    data,
  });
};

//设置最佳
const setActivityBestSubmission = (data) => {
  return $http({
    url: "/admin/activity/set_best",
    method: "post",
    data,
  });
};

//设置最佳
const setSubmissionFan = (data) => {
  return $http({
    url: "/admin/activity/set_submission_fan",
    method: "post",
    data,
  });
};

const commentList = (data) => {
  return $http({
    url: "/admin/activity/comment_list",
    method: "get",
    data,
  });
};

//设置最佳
const changeCommentShow = (data) => {
  return $http({
    url: "/admin/activity/set_comment_show",
    method: "post",
    data,
  });
};

export default {
  newsList,
  newsDetail,
  saveNotice,
  saveNoticeAsDraft,
  deleteActivity,
  setActivityBestSubmission,
  submissionSummary,
  isTop,
  detailSubmission,
  acceptSubmission,
  rejectSubmission,
  setSubmissionFan,
  commentList,
  changeCommentShow,
};
