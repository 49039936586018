import Router from "@/router";
import { setItem } from "@/utils/storage";
export default {
  namespaced: true,
  state: {
    bars: {
      tabsValue: "0",
      id: 0,
      tabsList: [],
    },
    vnode: false,
  },
  mutations: {
    setBars(state, data) {
      state.bars = data;
    },
    setVNode(state, data) {
      state.vnode = data;
    },
  },
  actions: {
    //增加tab
    addTabs({ state }, item) {
      let index = state.bars.id.toString();
      state.bars.tabsList.push({
        title: item.name,
        name: index,
        path: item.fullPath,
      });
      state.bars.tabsValue = index;
      state.bars.id++;
    },
    handleTabsClick({ state }) {
      let barList = state.bars.tabsList;
      let item = barList.find((bar) => {
        return bar.title === arguments[1].label;
      });
      Router.push({ path: item.path });
    },
  //从标签栏中移除特定路径的标签
  //处理相关的路由跳转和缓存清理操作
    removeTabByPath({ state }, item) {
      //拿到点击的所有的tab
      //item是指点击的path
      let barList = state.bars.tabsList;
      const index = barList.findIndex((bar) => {
        return bar.path === item || bar.path.indexOf(item + "?") == 0;
      });
      //两种
      if (
        state &&
        state.vnode &&
        state.vnode.parent &&
        state.vnode.parent.componentInstance &&
        state.vnode.parent.componentInstance.cache
      ) {
        let cache = state.vnode.parent.componentInstance.cache;
        let keys = state.vnode.parent.componentInstance.keys;
        keys = keys.filter((r) => r == item || r.indexOf(item + "?") == 0);
        keys.forEach((r) => {
          delete cache[r];
        });
      }
      if (index >= 0) {
        barList.splice(index, 1);
        console.log(barList);
        setItem("bars", state.bars);
        //删除tabs跳转最后一个标签 如果不是当前选择页面或没有tab则不做跳转
        if (barList.length == 0) {
          Router.push("/");
          return;
        }
        Router.push({ path: barList[barList.length - 1].path });
      }
    },
    //删除tab
    removeTab({ state }, item) {
      let barList = state.bars.tabsList;
      const index = barList.findIndex((bar) => {
        return bar.name === item;
      });
      let barItem = barList[index];
      if (
        state &&
        state.vnode &&
        state.vnode.parent &&
        state.vnode.parent.componentInstance &&
        state.vnode.parent.componentInstance.cache
      ) {
        let cache = state.vnode.parent.componentInstance.cache;
        let keys = state.vnode.parent.componentInstance.keys;
        keys = keys.filter((r) => r == barItem.path);
        keys.forEach((r) => {
          delete cache[r];
        });
      }
      barList.splice(index, 1);
      setItem("bars", state.bars);
      //删除tabs跳转最后一个标签 如果不是当前选择页面或没有tab则不做跳转
      if (state.bars.tabsValue != item || barList.length == 0) {
        Router.push("/");
        return;
      }
      Router.push({ path: barList[barList.length - 1].path });
    },
    //删除tab
    clearTab({ state }) {
      state.bars.tabsList = [];
      setItem("bars", state.bars);
    },
    rollback({ state }) {
      if (state.bars.tabsValue) {
        let barList = state.bars.tabsList;
        const index = barList.findIndex((bar) => {
          return bar.name === state.bars.tabsValue;
        });
        barList.splice(index, 1);
        setItem("bars", state.bars);
        //删除tabs跳转最后一个标签 如果不是当前选择页面或没有tab则不做跳转
        if (barList.length == 0) {
          Router.push("/");
          return;
        }
        Router.push({ path: barList[barList.length - 1].path });
      }
    },
  },
};
//state: 
//bars 是一个对象，包含了一些标签页（tabs）的相关信息，
//如当前选中的标签值、标签列表以及每个标签的唯一标识符
//vnode 是一个布尔值，表示某种节点。
//
// mutations: 
//setBars 用于更新 bars 状态，setVNode 用于更新 vnode 状态。
//
// actions
// addTabs：用于向标签列表中添加一个标签。
// handleTabsClick：处理标签的点击操作，执行路由跳转。
// removeTabByPath：从标签列表中移除具有特定路径的标签，同
// 时执行路由跳转和缓存清理操作。
// removeTab：用于删除特定标签。
// clearTab：用于清空标签列表。
// rollback：用于回滚标签状态。
