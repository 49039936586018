//查询列表
import $http from "../http";
//赛事日程的banner
const match_banners = (data) => {
  return $http({
    url: `/admin/match/session_banner_list`,
    method: "get",
    data,
  });
};

const match_save_banner = (data) => {
  return $http({
    url: `/admin/match/save_session_banner`,
    method: "post",
    data,
  });
};
//删除banner
const match_delete_banner = (data) => {
  return $http({
    url: `/admin/match/delete_session_banner`,
    method: "post",
    data,
  });
};

//列表
const list_black = (data) => {
  return $http({
    url: `/admin/black/member_black_list`,
    method: "get",
    data: data,
  });
};
const list_black_team = (data) => {
  return $http({
    url: `/admin/black/sect_black_list`,
    method: "get",
    data: data,
  });
};

//添加&编辑
const save_black = (data) => {
  return $http({
    url: `/admin/black/save_member_black`,
    method: "post",
    data,
  });
};
const save_black_sect = (data) => {
  return $http({
    url: `/admin/black/save_sect_black`,
    method: "post",
    data,
  });
};

//删除
const delete_black = (data) => {
  return $http({
    url: `/admin/black/delete_member_black`,
    method: "get",
    data,
  });
};
const delete_black_team = (data) => {
  return $http({
    url: `/admin/black/delete_sect_black`,
    method: "get",
    data,
  });
};
// 门派联盟 列表
const sect_alliance_list = (data) => {
  return $http({
    url: `/admin/sect/sect_alliance_list`,
    method: "get",
    data,
  });
};
//添加&编辑
const save_sect_alliance = (data) => {
  return $http({
    url: `/admin/sect/save_sect_alliance`,
    method: "post",
    data,
  });
};
// 门派联盟 删除、恢复
const update_status = (data) => {
  return $http({
    url: `/admin/sect/update_status`,
    method: "get",
    data,
  });
};

// 门派联盟 列表
const new_sect_alliance_list = (data) => {
  return $http({
    url: `/admin/sect/new/sect_alliance_list`,
    method: "get",
    data,
  });
};
//添加&编辑
const new_save_sect_alliance = (data) => {
  return $http({
    url: `/admin/sect/new/save_sect_alliance`,
    method: "post",
    data,
  });
};
// 保存视频地址
const save_video_info = (data) => {
  return $http({
    url: `/admin/match/save_video_info`,
    method: "post",
    data,
  });
};

// 门派联盟 删除、恢复
const new_update_status = (data) => {
  return $http({
    url: `/admin/sect/new/update_status`,
    method: "get",
    data,
  });
};
const sect_alliance_detail = (data) => {
  return $http({
    url: `/admin/sect/new/sect_detail`,
    method: "get",
    data,
  });
};
// 查询视频地址
const get_video_info = (data) => {
  return $http({
    url: `/admin/match/get_video_info`,
    method: "get",
    data,
  });
};
// 后台账号查询
const user_api_list = (data) => {
  return $http({
    url: `/admin/user/user_api_list`,
    method: "get",
    data,
  });
};
// 修改微信号
const save_wechat = (data) => {
  return $http({
    url: `/admin/user/update_we_chat`,
    method: "post",
    data,
  });
};
//
const home_list_banner = (data) => {
  return $http({
    url: `/admin/banner/banner_list`,
    method: "get",
    data,
  });
};

// 新增修改主页banner
const home_save_banner = (data) => {
  return $http({
    url: `/admin/banner/save_banner`,
    method: "post",
    data,
  });
};
// 新增修改主页banner
const home_status_banner = (data) => {
  return $http({
    url: `/admin/banner/banner_status`,
    method: "post",
    data,
  });
};
//新增通知
const save_notice = (data) => {
  return $http({
    url: `/admin/save_notice`,
    method: "post",
    data,
  });
};
const notice_user_uid = (data) => {
  return $http({
    url: `/admin/user/get_user_uid`,
    method: "get",
    data,
  });
};
//通知
const notice_info = (data) => {
  return $http({
    url: `/admin/notice_info`,
    method: "get",
    data,
  });
};

export default {
  notice_info,
  home_list_banner,
  home_save_banner,
  home_status_banner,
  save_notice,
  notice_user_uid,
  match_banners,
  match_save_banner,
  match_delete_banner,
  list_black,
  save_black,
  delete_black,
  delete_black_team,
  update_status,
  save_sect_alliance,
  sect_alliance_list,
  new_sect_alliance_list,
  new_save_sect_alliance,
  new_update_status,
  sect_alliance_detail,
  list_black_team,
  save_black_sect,
  save_video_info,
  get_video_info,
  user_api_list,
  save_wechat,
};
