import $http from '../http'
const login = (data) => {
    return $http({
        url:'/admin/login',
        method:'post',
        data
    })
}
export default{
    login
}
