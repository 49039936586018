export default {
  namespaced: true,
  state: {
    match_types: {
      s1: "门派预选赛S1",
      s2: "门派预选赛S2",
      s3:"门派预选赛S3",
      s1_pro: "门派联赛S1",
      s2_pro: "门派联赛S2",
      wdl: "第四届城市联赛",
    },
    subject_match_name: "",
  },
  mutations: {
    setName(state, data) {
      state.subject_match_name = data;
    },
  },
  actions: {},
};
