<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style src='@/style/global.less' lang='less'></style>

<style>
  .el-table__header thead tr th{
    /*background-color: #158799;*/
    background-color: #304156 !important;
    color: #ffffff;
  }

  /*.el-menu-item {*/
  /*  background: #304156;*/
  /*  color: #ffffff!important;*/
  /*}*/

  /*.el-menu-item:hover {*/
  /*  background: #415267!important;*/
  /*}*/

  /*.el-submenu .el-menu--inline .el-menu-item {*/
  /*  background: #ffffff;*/
  /*  color: #000000!important;*/
  /*}*/

  /*.k-and311 .is-active {*/
  /*  background-color: #415267!important;*/
  /*  color: #ffffff!important;*/
  /*}*/

  /*.k-and311 .el-submenu__title {*/
  /*  background-color: #304156!important;*/
  /*  color: #ffffff!important;*/
  /*}*/

  /*.k-and311 .is-active .el-submenu__title {*/
  /*  background-color: #415267!important;*/
  /*  color: #ffffff!important;*/
  /*}*/

  /*.k-and311 i {*/
  /*  color: #ffffff!important;*/
  /*}*/

  /*.el-submenu .el-menu--inline .el-menu-item:hover {*/
  /*  background: #E8F3F4!important;*/
  /*  color: #000000!important;*/
  /*}*/

  /*.el-submenu .el-menu--inline .el-menu-item.is-active {*/
  /*  background-color: #E8F3F4!important;*/
  /*  color: #000000!important;*/
  /*}*/

  .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid transparent;
  }

  .el-table--border, .el-table--group {
    border-left: 1px solid transparent;
  }
</style>
