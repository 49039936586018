import $http from "../http";
import axios from "axios";
import Cookies from "js-cookie";
import router from "../../router";
import { Message } from "element-ui";

//查询列表
const uploadFile = async (fileUrl, name, file, cacheControl) => {
  console.log("----", fileUrl, name, file, cacheControl);
  let { data, errorCode } = await $http({
    url: "/admin/sts",
    method: "get",
  });

  if (errorCode != "0000") return false;

  let sendData = new FormData(); // 上传文件的data参数

  sendData.append("OSSAccessKeyId", data.OSSAccessKeyId);

  sendData.append("policy", data.policy);

  sendData.append("signature", data.signature);

  sendData.append("key", fileUrl); //上传的文件路径

  if (cacheControl) {
    sendData.append("Cache-Control", cacheControl); //文件缓存设置
  }

  sendData.append("success_action_status", 200); // 指定返回的状态码

  sendData.append("file", file);

  sendData.append("name", name);

  let ossUrl = data.oss_url;
  let cndUrl = data.cdn_url;
  return await new Promise((resolve, reject) => {
    axios
      .post(ossUrl, sendData)
      .then((res) => {
        let pictureUrl = cndUrl + "/" + fileUrl; //获得到的url需要将其存数据库中
        resolve(pictureUrl);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

const uploadFileToServer = async (url, file, data) => {
  let sendData = new FormData(); // 上传文件的data参数

  const token = Cookies.get("token");
  //判断token
  if (!token) {
    if (router.app._route.name !== "login") {
      router.push("/login");
      return;
    }
  }

  sendData.append("file", file);

  if (data) {
    Object.keys(data).forEach((r) => {
      if (r && data[r]) {
        sendData.append(r, data[r] + "");
      }
    });
  }

  return new Promise((resolve, reject) => {
    let pathUrl =
      process.env.VUE_APP_API_MODE === "proxy"
        ? `${process.env.VUE_APP_SERVER}:${process.env.VUE_APP_SERVER_PORT}`
        : `${process.env.VUE_APP_API}`;
    axios
      .post(pathUrl + url, sendData, {
        headers: { token: token, Authorization: token },
      })
      .then((response) => {
        const status = response.status;
        let message = "";

        if (status < 200 || status >= 300) {
          // 处理http错误，抛到业务代码
          message = "服务器异常，请联系管理员！";
          if (status == 401) {
            message = "未授权，请重新登录(401)";
          }

          Message({
            message: message,
            type: "warning",
          });

          if (typeof response.data === "string") {
            response.data = { msg: message };
          } else {
            response.data.msg = message;
          }
        }

        const errorCode = response.data.code;
        response.data.errorCode = errorCode + "";
        if (errorCode == 0) {
          response.data.errorCode = "0000";
        }

        if (errorCode != 0) {
          let message = "";
          switch (errorCode) {
            case 1004:
              message = "token校验失败";
              break;
            case 1002:
              message = "用户名或密码错误";
              break;
            case 1000:
              message = "服务器异常，请联系管理员！";
              break;
            default:
              message = response.data.msg;
          }
          Message({
            message: message,
            type: "warning",
          });
        }

        resolve(response.data);
      })
      .catch((error) => {
        // 错误抛到业务代码
        let errorMsg = {};
        errorMsg.errorCode = -1;
        errorMsg.data = null;
        errorMsg.message = error;
        if (error.response && error.response.status == 403) {
          Message({
            message: "登录超时，请重新登录！",
            type: "warning",
          });
        } else if (error.response && error.response.status == 401) {
          errorMsg.message = "未授权，请重新登录(401)";
        } else {
          Message({
            message: "服务器异常，请联系管理员！",
            type: "warning",
          });
        }

        resolve(errorMsg);
      });
  });
};

export default {
  uploadFile,
  uploadFileToServer,
};
