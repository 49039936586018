//查询列表
import $http from "../../http";

const subject_list = (data) => {
  return $http({
    url: `/admin/match/new_match/get_match`,
    method: "get",
    data,
  });
};
const get_team_subject_rank = (data) => {
  return $http({
    url: `/admin/match/new_match/get_team_rank`,
    method: "get",
    data,
  });
};

const subject_detail = (data) => {
  return $http({
    url: `/admin/match/new_match/get_match_segment`,
    method: "get",
    data,
  });
};
//修改门派积分
const new_stage_change_team_rank = (data) => {
  return $http({
    url:
      data.type_id == "s3" || data.type_id == "s2" || data.type_id == "s1"
        ? "/admin/match/stage_change_team_rank"
        : `/admin/match/new_match/change_team_score`,
    method: "post",
    data,
  });
};
//s4调整排序
const S4_stage_change_team_sort = (data) => {
  return $http({
    url:`/admin/match/new_match/change_team_sort`,
    method: "post",
    data,
  });
};
const new_stage_change_team_sort = (data) => {
  return $http({
    url:
    data.type_id == "s3" || data.type_id == "s2" || data.type_id == "s1"
      ? "/admin/match/stage_change_member_sort"
      : `/admin/match/new_match/change_member_sort`,
  method: "post",
  data,
  });
};
const new_person_change_team_sort = (data) => {
  return $http({
    url:
    data.type_id == "s3" || data.type_id == "s2" || data.type_id == "s1"
        ? "/admin/match/stage_change_team_sort"
        : `/admin/match/new_match/change_team_sort`,
    method: "post",
    data,
  });
};
const new_person_change_team_rank = (data) => {
  return $http({
    url:
    data.type_id == "s3" || data.type_id == "s2" || data.type_id == "s1"
      ? "/admin/match/stage_change_member_score"
      : `/admin/match/new_match/change_member_score`,
  method: "post",
  data,
  });
};

//添加&编辑
const save_subject = (data) => {
  return $http({
    url: `/admin/match/new_match/update`,
    method: "post",
    data,
  });
};
// 编辑赛段
const save_subject_season = (data) => {
  return $http({
    url: `/admin/match/new_match/update_match_season`,
    method: "post",
    data,
  });
};

// 查询分组
const season_group_list_new = (data) => {
  return $http({
    url: `/admin/match/new_match/season_group_list`,
    method: "get",
    data,
  });
};

const get_team_subject_member_rank = (data) => {
  return $http({
    url: `/admin/match/new_match/get_member_rank`,
    method: "get",
    data,
  });
};

//删除
const deleteInfo = (_id) => {
  return $http({
    url: `/admin/match/delete`,
    method: "post",
    data: {
      _id: _id,
    },
  });
};
// 获取赛事
const match_list = (data) => {
  return $http({
    url: `/admin/match/new_match/get_match`,
    method: "get",
    data,
  });
};
// 获取下拉框赛事
const match_list_names = (data) => {
  return $http({
    url: `/admin/match/new_match/match_name`,
    method: "get",
    data,
  });
};
// 获取下拉框所有赛段
const match_list_stages_names = (data) => {
  return $http({
    url: `/admin/match/new_match/get_all_season`,
    method: "get",
    data,
  });
};
// 新增签到
const saveSignIn = (data) => {
  return $http({
    url: `/admin/match/sign_in/save`,
    method: "post",
    data,
  });
};

// 获取赛事详情
const match_detail = (id) => {
  return $http({
    url: `/admin/match/new_match/get_match_detail?_id=` + id,
    method: "get",
  });
};
const match_detail_stage = (id) => {
  return $http({
    url: `/admin/match/new_match/get_segment_detail?_id=` + id,
    method: "get",
  });
};
// 删除分组
const delete_season_group_new = (data) => {
  return $http({
    url: `/admin/match/new_match/delete_season_group`,
    method: "get",
    data,
  });
};
// 查询分组门派
const newsDetail_session = (data) => {
  return $http({
    url: `/admin/match/new_match/get_group_detail`,
    method: "get",
    data,
  });
};
const new_config_session = (data) => {
  return $http({
    url: `/admin/match/new_match/get_session_config`,
    method: "get",
    data,
  });
};

// 获取全量队伍（分组管理中的队伍管理用）
const new_group_session = (data) => {
  return $http({
    url: `/admin/match/new_match/match_team_all`,
    method: "get",
    data,
  });
};
// 获取队伍信息
const new_teams = (data) => {
  return $http({
    url: `/admin/match/new_match/teams`,
    method: "get",
    data,
  });
};
const new_delete_team = (data) => {
  return $http({
    url: `/admin/match/new_match/delete_team`,
    method: "post",
    data,
  });
};
// 恢复队伍
const new_recovery_team = (data) => {
  return $http({
    url: `/admin/match/new_match/recovery_team`,
    method: "post",
    data,
  });
};
const detail_team_new = (data) => {
  return $http({
    url: `/admin/match/new_match/detail_team`,
    method: "get",
    data,
  });
};
const new_save_team = (data) => {
  return $http({
    url: `/admin/match/new_match/save_team`,
    method: "post",
    data,
  });
};
// 新增，编辑分组
const save_season_group = (data) => {
  return $http({
    url: `/admin/match/new_match/save_season_group`,
    method: "post",
    data,
  });
};
// 生成场次
const new_save_match_season = (data) => {
  return $http({
    url: `/admin/match/new_match/save_session`,
    method: "post",
    data,
  });
};
// 编辑场次结果
const new_stage_set_session_result = (data) => {
  return $http({
    url: `/admin/match/new_match/set_session_result`,
    method: "post",
    data,
  });
};
const new_session_add_group_league = (data) => {
  return $http({
    url: `/admin/match/new_match/save_session_sects`,
    method: "post",
    data,
  });
};

// 获取场次门派
const new_stage_group_list_league = (data) => {
  return $http({
    url: `/admin/match/new_match/get_session_sects`,
    method: "get",
    data,
  });
};
const new_session_add_group = (data) => {
  return $http({
    url: `/admin/match/new_match/get_list_sects`,
    method: "get",
    data,
  });
};
// s4赛段分组
const new_match_season_all = (data) => {
  return $http({
    url: `/admin/match/new_match/match_season_all`,
    method: "get",
    data,
  });
};
const sign_newsDetail = (data) => {
  return $http({
    url: `/admin/match/sign_in/get_sign_info`,
    method: "get",
    data,
  });
};

export default {
  subject_list,
  subject_detail,
  save_subject,
  deleteInfo,
  save_subject_season,
  get_team_subject_rank,
  get_team_subject_member_rank,
  match_list,
  match_list_names,
  match_detail,
  match_list_stages_names,
  match_detail_stage,
  season_group_list_new,
  delete_season_group_new,
  newsDetail_session,
  new_group_session,
  new_teams,
  new_delete_team,
  new_recovery_team,
  detail_team_new,
  new_save_team,
  save_season_group,
  new_save_match_season,
  new_config_session,
  new_stage_change_team_rank,
  new_stage_change_team_sort,
  new_person_change_team_sort,
  new_person_change_team_rank,
  new_stage_group_list_league,
  new_stage_set_session_result,
  new_session_add_group_league,
  new_session_add_group,
  new_match_season_all,
  sign_newsDetail,
  saveSignIn,
  S4_stage_change_team_sort
};
