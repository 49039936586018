import $http from '../http'
import $sect from './armory/sect'
import $titles from './armory/titles'
//查询列表
const seasonList = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/seasonlist`,
    method: 'get',
    data,
  })
}

const seasonDetail = (_id, type) => {
  return $http({
    url: `/admin/armory/${type}/seasondetail`,
    method: 'get',
    data: {
      _id: _id,
    },
  })
}

//添加&编辑
const saveSeason = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/saveseason`,
    method: 'post',
    data,
  })
}

//删除
const deleteSeason = (_id, type) => {
  return $http({
    url: `/admin/armory/${type}/deleteseason`,
    method: 'post',
    data: {
      _id: _id,
    },
  })
}

//恢复
const recoverySeason = (_id, type) => {
  return $http({
    url: `/admin/armory/${type}/recoveryseason`,
    method: 'post',
    data: {
      _id: _id,
    },
  })
}

//改状态
const changeSeasonStatus = (_id, status, type) => {
  return $http({
    url: `/admin/armory/${type}/changeseasonstatus`,
    method: 'post',
    data: {
      _id: _id,
      status,
    },
  })
}

//查询列表
const list = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/list`,
    method: 'get',
    data,
  })
}

const detail = (_id, type) => {
  return $http({
    url: `/admin/armory/${type}/detail`,
    method: 'get',
    data: {
      _id: _id,
    },
  })
}

//添加&编辑
const save = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/save`,
    method: 'post',
    data,
  })
}

//删除
const deleteInfo = (_id, type) => {
  return $http({
    url: `/admin/armory/${type}/delete`,
    method: 'post',
    data: {
      _id: _id,
    },
  })
}

//恢复
const recovery = (_id, type) => {
  return $http({
    url: `/admin/armory/${type}/recovery`,
    method: 'post',
    data: {
      _id: _id,
    },
  })
}

const getIntegralRule = (type) => {
  return $http({
    url: `/admin/armory/${type}/getintegralrule`,
    method: 'get',
    data: {},
  })
}

const saveIntegralRule = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/saveintegralrule`,
    method: 'post',
    data,
  })
}

//积分列表
const rank = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/rank`,
    method: 'get',
    data,
  })
}

const detailRank = (season_id, ref_id, type) => {
  return $http({
    url: `/admin/armory/${type}/rankdetail`,
    method: 'get',
    data: {
      season_id,
      ref_id,
    },
  })
}

//添加&编辑
const saveRank = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/changerank`,
    method: 'post',
    data,
  })
}

//发布积分
const publishRank = (type, _ids) => {
  return $http({
    url: `/admin/armory/${type}/publishrank`,
    method: 'post',
    data: {
      _ids,
    },
  })
}

//删除积分
const deleteRank = (type, _id) => {
  return $http({
    url: `/admin/armory/${type}/deleterank`,
    method: 'post',
    data: {
      _id,
    },
  })
}

//恢复积分
const recoveryRank = (type, _id) => {
  return $http({
    url: `/admin/armory/${type}/recoveryrank`,
    method: 'post',
    data: {
      _id,
    },
  })
}

const detailRule = (type) => {
  return $http({
    url: `/admin/armory/${type}/detailrule`,
    method: 'get',
    data: {},
  })
}

//添加&编辑
const saveRule = (type, data) => {
  return $http({
    url: `/admin/armory/${type}/saverule`,
    method: 'post',
    data,
  })
}

const importStatus = (type, sub_type) => {
  return $http({
    url: `/admin/armory/${type}/import${sub_type}status`,
    method: 'get',
    data: {},
  })
}

const importHistory = (type, sub_type) => {
  return $http({
    url: `/admin/armory/${type}/import${sub_type}history`,
    method: 'get',
    data: {},
  })
}
// 门派联盟审核
const reviewSect = (data) => {
  return $http({
    url: `/admin/armory/sect/review`,
    method: 'post',
    data: data,
  })
}
// 后台保存二维码
const saveWx = (data) => {
  return $http({
    url: '/admin/management/official/save',
    method: 'post',
    data: data,
  })
}
// 后台记者团，裁判数据，avater
const officialDetail = (data) => {
  return $http({
    url: '/admin/management/official/detail',
    method: 'get',
    data: data,
  })
}
//添加裁判,编辑裁判
const officialsave = (data) => {
  return $http({
    url: `/admin/management/referee/save`,
    method: 'post',
    data: data,
  })
}
//编辑裁判
const officialedit = (data) => {
  return $http({
    url: `/admin/management/referee/save`,
    method: 'post',
    data: data,
  })
}
// 记者团
const presssave = (data) => {
  return $http({
    url: `/admin/management/press/save`,
    method: 'post',
    data: data,
  })
}
// 获取裁判列表
const officiaList = (data) => {
  return $http({
    url: `/admin/management/referee/list`,
    method: 'get',
    data: data,
  })
}
// 记者团列表
const pressList = (data) => {
  return $http({
    url: `/admin/management/press/list`,
    method: 'get',
    data: data,
  })
}
// 获取裁判列表恢复
const officiaListrecovery = (data) => {
  return $http({
    url: `/admin/management/referee/update_status`,
    method: 'get',
    data: data,
  })
}
// 记者恢复，删除
const press_update_status = (data) => {
  return $http({
    url: `/admin/management/press/update_status`,
    method: 'get',
    data: data,
  })
}
// 裁判详情
const refereeDetail = (data) => {
  return $http({
    url: `/admin/management/referee/detail`,
    method: 'get',
    data: data,
  })
}
// 记者
const pressDetail = (data) => {
    return $http({
      url: `/admin/management/press/detail`,
      method: 'get',
      data: data,
    })
  }
// 裁判导出数据
const export_TeamToExcel = (data) => {
  return $http({
    url: `/admin/management/referee/export_TeamToExcel`,
    method: 'post',
    data: data,
  })
}
// 记者团导出数据
const press_export_TeamToExcel = (data) => {
  return $http({
    url: `/admin/management/press/export_TeamToExcel`,
    method: 'post',
    data: data,
  })
}

export default {
  press_export_TeamToExcel,
    pressDetail,
  presssave,
  press_update_status,
  pressList,
  refereeDetail,
  export_TeamToExcel,
  officiaListrecovery,
  officiaList,
  officialedit,
  officialsave,
  saveWx,
  officialDetail,
  reviewSect,
  seasonList,
  seasonDetail,
  saveSeason,
  deleteSeason,
  recoverySeason,
  changeSeasonStatus,
  list,
  detail,
  save,
  deleteInfo,
  recovery,
  getIntegralRule,
  saveIntegralRule,
  rank,
  detailRank,
  saveRank,
  publishRank,
  deleteRank,
  recoveryRank,
  detailRule,
  saveRule,
  importStatus,
  importHistory,
  titles: $titles,
  ...$sect,
}
