import $http from '../../http'

//列表
const list = (type) => {
    return $http({
        url:`/admin/armory/${type}/titles`,
        method:'get',
        data: {}
    })
}

//添加&编辑
const save = (type, data) => {
    return $http({
        url:`/admin/armory/${type}/savetitle`,
        method:'post',
        data
    })
}

//删除
const remove = (type, data) => {
    return $http({
        url:`/admin/armory/${type}/removetitle`,
        method:'post',
        data
    })
}

export default{
    list,
    save,
    remove
}
