<template>
  <div id="app">
<!--    <div>-->
<!--      <b>经度：</b>-->
<!--      <b>{{ currentPicked.latlng.lat }}</b>-->
<!--    </div>-->
<!--    <div>-->
<!--      <b>纬度：</b>-->
<!--      <b>{{ currentPicked.latlng.lng }}</b>-->
<!--    </div>-->
<!--    <div>-->
<!--      <b>城市：</b>-->
<!--      <b>{{ currentPicked.cityname }}</b>-->
<!--    </div>-->
<!--    <div>-->
<!--      <b>位置信息：</b>-->
<!--      <b>{{ currentPicked.poiaddress }}{{ currentPicked.poiname }}</b>-->
<!--    </div>-->

    <div>
      <iframe style="border: none;" :src="map_src" width="600" height="400"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "mapPick",
  emits: ['onSelect'],
  props: {
    gis: {
      type: Object
    }
  },
  watch: {
    "gis":{
      handler:function(val){
        this.setUrl();
      },
      immediate:true
    }
  },
  mounted() {
    this.setUrl();
    window.addEventListener("message", this.handleMapPickedMessage, false);
  },
  destroyed() {
    window.removeEventListener("message", this.handleMapPickedMessage, false);
  },
  data() {
    return {
      map_src: '',
      currentPicked: {
        latlng: {
          lat: "",
          lng: ""
        },
        url: `https://apis.map.qq.com/tools/locpicker?search=1&type=1&policy=1&key=UU2BZ-B6RKG-QCTQW-IA6XO-5NHSH-Q6BYM&referer=myapp`
      },
      mapApiKey: "UU2BZ-B6RKG-QCTQW-IA6XO-5NHSH-Q6BYM",
      cityname: '',
      poiaddress: '',
      poiname: ''
    };
  },
  methods: {
    setUrl() {
      let url = this.currentPicked.url;
      if(this.gis && this.gis.lat && this.gis.lng){
        url += '&coord=' + this.gis.lat + ',' + this.gis.lng;
      }

      this.map_src = url;
    },
    handleMapPickedMessage(event) {
      /**
       * 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
       */
      let loc = event.data;
      console.log(loc)
      // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
      if (loc && loc.module != "locationPicker") return;
      this.currentPicked = {
        ...this.currentPicked,
        ...loc
      };

      this.$emit("onSelect", this.currentPicked);
      // console.log(" currentPicked.value ", currentPicked.value );
    }
  }
}
</script>

<style scoped>

</style>
