import $http from '../http'
//查询列表
const activityList = (data) => {
    return $http({
        url:'/admin/activity/list',
        method:'get',
        data
    })
}

const activityDetail = (_id) => {
    return $http({
        url:'/admin/activity/detail',
        method:'get',
        data: {
            _id: _id
        }
    })
}

//添加&编辑
const saveActivity = (data) => {
    return $http({
        url:'/admin/activity/save',
        method:'post',
        data
    })
}

//添加&编辑
const saveActivityAsDraft = (data) => {
    return $http({
        url:'/admin/activity/saveDraft',
        method:'post',
        data
    })
}

//删除活动
const deleteActivity = (data) => {
    return $http({
        url:'/admin/activity/dellist',
        method:'post',
        data
    })
}

const submissionSummary = (_id) => {
    return $http({
        url:'/admin/activity/summary_submission',
        method:'get',
        data: {
            _id: _id
        }
    })
}

const submissionList = (data) => {
    return $http({
        url:'/admin/activity/list_submission',
        method:'get',
        data
    })
}

const detailSubmission = (data) => {
    return $http({
        url:'/admin/activity/detail_submission',
        method:'get',
        data
    })
}

const saveSubmission = (act_id, data) => {
    return $http({
        url:'/admin/activity/save_submission?act_id=' + act_id,
        method:'post',
        data
    })
}

const acceptSubmission = (data) => {
    return $http({
        url:'/admin/activity/accept_submission',
        method:'post',
        data
    })
}

const rejectSubmission = (data) => {
    return $http({
        url:'/admin/activity/reject_submission',
        method:'post',
        data
    })
}

//设置最佳
const setActivityBestSubmission = (data) => {
    return $http({
        url:'/admin/activity/set_best',
        method:'post',
        data
    })
}

//设置最佳
const setSubmissionFan = (data) => {
    return $http({
        url:'/admin/activity/set_submission_fan',
        method:'post',
        data
    })
}

const commentList = (data) => {
    return $http({
        url:'/admin/activity/comment_list',
        method:'get',
        data
    })
}

//设置最佳
const changeCommentShow = (data) => {
    return $http({
        url:'/admin/activity/set_comment_show',
        method:'post',
        data
    })
}

export default{
    activityList,
    activityDetail,
    saveActivity,
    saveActivityAsDraft,
    deleteActivity,
    setActivityBestSubmission,
    submissionSummary,
    submissionList,
    detailSubmission,
    acceptSubmission,
    rejectSubmission,
    saveSubmission,
    setSubmissionFan,
    commentList,
    changeCommentShow
}
