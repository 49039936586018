//查询列表
import $http from "../../http";
import match_s4 from "./match_s4";

const list = (data) => {
  return $http({
    url: `/admin/match/list`,
    method: "get",
    data,
  });
};

const detail = (_id) => {
  return $http({
    url: `/admin/match/detail`,
    method: "get",
    data: {
      _id: _id,
    },
  });
};

//添加&编辑
const save = (type, data) => {
  return $http({
    url: `/admin/match/${type}/save`,
    method: "post",
    data,
  });
};
// 保存s2联赛
const save_s2_pro = (type, data) => {
  return $http({
    url: `/admin/match/new_match/save`,
    method: "post",
    data,
  });
};

const save_info_points = (type, data) => {
  return $http({
    url: `/admin/chargePoints/save_info`,
    method: "post",
    data,
  });
};

//删除
const deleteInfo = (_id) => {
  return $http({
    url: `/admin/match/delete`,
    method: "post",
    data: {
      _id: _id,
    },
  });
};

//恢复
const recovery = (_id) => {
  return $http({
    url: `/admin/match/recovery`,
    method: "post",
    data: {
      _id: _id,
    },
  });
};

const getSectMembers = (sect_id, is_bind, is_audition) => {
  return $http({
    url: `/admin/match/get_sect_members`,
    method: "get",
    data: {
      sect_id: sect_id,
      is_bind: false,
      is_audition: is_audition,
    },
  });
};

const reload_config = (path) => {
  return $http({
    url: `/admin/match/reload_config`,
    method: "post",
    data: {
      path: path,
    },
  });
};

const stage_list = (data) => {
  return $http({
    url: `/admin/match/stage_list`,
    method: "get",
    data,
  });
};

const s4_stage_list = (data) => {
  return $http({
    url: `/admin/new_match/stage/stage_list`,
    method: "get",
    data,
  });
};

const save_stage = (data) => {
  return $http({
    url: `/admin/match/save_stage`,
    method: "post",
    data,
  });
};

const delete_stage = (data) => {
  return $http({
    url: `/admin/match/delete_stage`,
    method: "post",
    data,
  });
};

const publish_stage = (data) => {
  return $http({
    url: `/admin/match/publish_stage`,
    method: "post",
    data,
  });
};

const settle_stage = (data) => {
  return $http({
    url: `/admin/match/settle_stage`,
    method: "post",
    data,
  });
};

const teams = (data) => {
  return $http({
    url: `/admin/match/teams`,
    method: "get",
    data,
  });
};
const charge_points_info = (data) => {
  return $http({
    url: `/admin/chargePoints/charge_points_info`,
    method: "get",
    data,
  });
};

//导入s1数据
const teams1 = (data) => {
  return $http({
    url: `/admin/match/teams`,
    method: "get",
    data,
  });
};
const get_info_rule = (data) => {
  return $http({
    url: `/admin/chargePoints/get_info`,
    method: "get",
    data,
  });
};

const detail_team = (match_id, _id) => {
  return $http({
    url: `/admin/match/detail_team`,
    method: "get",
    data: {
      match_id,
      _id: _id,
    },
  });
};

//添加&编辑
const save_team = (data) => {
  return $http({
    url: `/admin/match/save_team`,
    method: "post",
    data,
  });
};
const save_team_wdl = (data) => {
  return $http({
    url: `/admin/match/wdl/save_team`,
    method: "post",
    data,
  });
};

//删除
const delete_team = (match_id, _id) => {
  return $http({
    url: `/admin/match/delete_team`,
    method: "post",
    data: {
      match_id,
      _id: _id,
    },
  });
};
//删除
const delete_charge_points = (status, _id) => {
  return $http({
    url: `/admin/chargePoints/delete_charge_points`,
    method: "post",
    data: {
      status: status,
      _id: _id,
    },
  });
};
//恢复
const recovery_team = (match_id, _id) => {
  return $http({
    url: `/admin/match/recovery_team`,
    method: "post",
    data: {
      match_id,
      _id: _id,
    },
  });
};

//社群二维码
const group_qr_expired = (_id) => {
  return $http({
    url: `/admin/match/group_qr_expired`,
    method: "get",
    data: {
      match_id: _id,
    },
  });
};

//社群二维码
const set_group_qr_expired = (_id) => {
  return $http({
    url: `/admin/match/set_group_qr_expired`,
    method: "post",
    data: {
      match_id: _id,
    },
  });
};

const stage_group_list = (data) => {
  return $http({
    url: `/admin/match/stage_group_list`,
    method: "get",
    data,
  });
};
//联赛
const stage_group_list_league = (data) => {
  return $http({
    url: `/admin/match/s1_pro/get_session_sects`,
    method: "get",
    data,
  });
};
const session_add_group_league = (data) => {
  return $http({
    url: `/admin/match/s1_pro/save_session_sects`,
    method: "post",
    data,
  });
};
const session_add_group_s2 = (data) => {
  return $http({
    url: `/admin/chargePoints/save_group_team`,
    method: "post",
    data,
  });
};

const update_sect_status_s2 = (data) => {
  return $http({
    url: `/admin/chargePoints/update_sect_status`,
    method: "post",
    data,
  });
};
const submit_sect_update_s2 = (data) => {
  return $http({
    url: `/admin/chargePoints/submit_sect_update`,
    method: "post",
    data,
  });
};
const member_team_wdl = (data) => {
  return $http({
    url: `/admin/match/wdl/get_members`,
    method: "get",
    data,
  });
};

const stage_groups = (data) => {
  return $http({
    url: `/admin/match/stage_groups`,
    method: "get",
    data,
  });
};

const stage_group_team = (data) => {
  return $http({
    url: `/admin/match/stage_group_team`,
    method: "get",
    data,
  });
};

const stage_switch_group = (data) => {
  return $http({
    url: `/admin/match/stage_switch_group`,
    method: "post",
    data,
  });
};


const session_switch_group = (data) => {
  return $http({
    url: `/admin/match/session_switch_group`,
    method: "post",
    data,
  });
};
// s3复用
const stage_switch_group_s2 = (data) => {
  return $http({
    url: `/admin/chargePoints/switch_group`,
    method: "post",
    data,
  });
};

//添加联赛门派id
const session_add_group = (data) => {
  return $http({
    url: `/admin/match/s1_pro/get_list_sects`,
    method: "get",
    data,
  });
};
//赛段 列表
const match_season_list = (data) => {
  return $http({
    url: `/admin/match/s1_pro/match_season_list`,
    method: "get",
    data,
  });
};
//赛段 新增、编辑
const save_match_season = (data, type) => {
  return $http({
    url:
      `${type}` == "s2_pro"
        ? "/admin/match/new_match/save_match_season"
        : "/admin/match/s1_pro/save_match_season",
    method: "post",
    data,
  });
};
//赛段 删除
const delete_match_season = (data, type) => {
  return $http({
    url:
      `${type}` == "s2_pro"
        ? "/admin/match/new_match/delete_match_season"
        : "/admin/match/s1_pro/delete_match_season",
    method: "get",
    data,
  });
};
//分组 列表
const season_group_list = (data) => {
  return $http({
    url: `/admin/match/s1_pro/season_group_list`,
    method: "get",
    data,
  });
};
//s3获取赛段分组,用于编辑场次
const match_season_all_s3 = (data) => {
  return $http({
    url: `/admin/match/new_match/match_season_all`,
    method: "get",
    data,
  });
};

const match_season_all = (data) => {
  return $http({
    url: `/admin/match/s1_pro/match_season_all`,
    method: "get",
    data,
  });
};
//分组 新增、编辑
const save_season_group = (data) => {
  return $http({
    url: `/admin/match/s1_pro/save_season_group`,
    method: "post",
    data,
  });
};
//分组 删除
const delete_season_group = (data) => {
  return $http({
    url: `/admin/match/s1_pro/delete_season_group`,
    method: "get",
    data,
  });
};

const stage_session_list = (data) => {
  return $http({
    url: `/admin/match/stage_session_list`,
    method: "get",
    data,
  });
};

const stage_set_pattern = (data) => {
  return $http({
    url: `/admin/match/stage_set_pattern`,
    method: "post",
    data,
  });
};

const stage_session_result = (data) => {
  return $http({
    url: `/admin/match/stage_session_result`,
    method: "get",
    data,
  });
};

const stage_set_session_result = (data, type) => {
  return $http({
    url:
      type.matchType != "league"
        ? "/admin/match/stage_set_session_result"
        : `/admin/match/${type.type_id}/set_session_result
      `,
    method: "post",
    data,
  });
};

const match_banners = (data) => {
  return $http({
    url: `/admin/match/match_banners`,
    method: "get",
    data,
  });
};

const match_save_banner = (data) => {
  return $http({
    url: `/admin/match/match_save_banner`,
    method: "post",
    data,
  });
};

const match_delete_banner = (data) => {
  return $http({
    url: `/admin/match/match_delete_banner`,
    method: "post",
    data,
  });
};
//阶段排行榜
const stage_team_rank = (data) => {
  return $http({
    url:
      data.matchType != "league"
        ? `/admin/match/stage_team_rank`
        : `/admin/match/${data.type_id}/get_team_rank`,
    method: "get",
    data,
  });
};

//个人排行榜
const person_team_rank = (data) => {
  return $http({
    url:
      data.matchType != "league"
        ? `/admin/match/stage_member_rank`
        : `/admin/match/${data.type_id}/get_member_rank`,
    method: "get",
    data,
  });
};
//修改门派积分
const stage_change_team_rank = (data) => {
  return $http({
    url:
      data.matchType != "league"
        ? "/admin/match/stage_change_team_rank"
        : `/admin/match/${data.type_id}/change_team_score`,
    method: "post",
    data,
  });
};
//修改积分
const person_change_team_rank = (data) => {
  return $http({
    url:
      data.matchType != "league"
        ? "/admin/match/stage_change_member_score"
        : `/admin/match/${data.type_id}/change_member_score`,
    method: "post",
    data,
  });
};

//修改个人积分同分排序
const person_change_team_sort = (data) => {
  return $http({
    url:
      data.matchType != "league"
        ? "/admin/match/stage_change_member_sort"
        : `/admin/match/${data.type_id}/change_member_sort`,
    method: "post",
    data,
  });
};
const stage_change_team_sort = (data) => {
  return $http({
    url:
      data.matchType != "league"
        ? "/admin/match/stage_change_team_sort"
        : `/admin/match/${data.type_id}/change_team_sort`,
    method: "post",
    data,
  });
};
export function update_charge_sect(data) {
  return $http({
    url: "/admin/chargePoints/update_charge_sect",
    method: "post",
    data,
  });
}
const publish_rank = (data) => {
  return $http({
    url: `/admin/match/publish_rank`,
    method: "post",
    data,
  });
};
const stage_change_s2_rank = (data) => {
  return $http({
    url: `/admin/chargePoints/update_score`,
    method: "post",
    data,
  });
};
const update_charge_result = (data) => {
  return $http({
    url: `/admin/chargePoints/update_charge_result`,
    method: "post",
    data,
  });
};
const stage_escort_sects = (data) => {
  return $http({
    url: `/admin/chargePoints/by_charge_points_info`,
    method: "get",
    data,
  });
};
const member_black_uid = (data) => {
  return $http({
    url: `/admin/black/member_black_uid`,
    method: "get",
    data,
  });
};
/** 添加或者修改复盘结果 */
export function saveReplay(data) {
  return $http({
    url: "/admin/match/stage_create_replay",
    method: "post",
    data,
  });
}
/** 添加或者修改复盘图 */
export function saveReplayPic(data) {
  return $http({
    url: "/admin/match/save_replay_image",
    method: "post",
    data,
  });
}
/** 查询复盘的数据 */
export function getReplay(data) {
  return $http({
    url: "/admin/match/stage_find_replay",
    method: "get",
    data,
  });
}
// 发布
export function releaseCourse(data) {
  return $http({
    url: "/admin/match/new_match/publish_session",
    method: "post",
    data,
  });
}
// s4编辑场次保存
const s4_save_stage = (data) => {
  return $http({
    url: `/admin/new_match/stage/save_stage`,
    method: "post",
    data,
  });
};
// s4编辑场次结算
const s4_settle_stage = (data) => {
  return $http({
    url: `/admin/new_match/stage/settle_stage`,
    method: "post",
    data,
  });
  
};
//
const s4_delete_stage = (data) => {
  return $http({
    url: `/admin/new_match/stage/delete_stage`,
    method: "post",
    data,
  });
  
};
// s4查询分组
const s4_season_group_list = (data) => {
  return $http({
    url: `/admin/match/new_match/season_group_list`,
    method: "get",
    data,
  });
};
  //获取全量队伍/分组操作
  const s4_match_team_all = (data) => {
    return $http({
      url: `/admin/match/new_match/match_team_all`,
      method: "get",
      data,
    });
  };
   //获取赛段分组/分组操作
   const s4_match_season_all = (data) => {
    return $http({
      url: `/admin/match/new_match/match_season_all`,
      method: "get",
      data,
    });
  };
  
//发布分组
const s4_publish_stage = (data) => {
  return $http({
    url: `/admin/new_match/stage/publish_stage`,
    method: "POST",
    data,
  });
};
// 预选赛编辑门派/参赛选手换人

const s4_update_session_player = (data) => {
  return $http({
    url: `/admin/new_match/stage/update_session_player`,
    method: "POST",
    data,
  });
};
export default {
  list,
  detail,
  save,
  save_s2_pro,
  deleteInfo,
  save_info_points,
  recovery,
  getSectMembers,
  reload_config,
  teams,
  get_info_rule,
  detail_team,
  save_team,
  save_team_wdl,
  delete_team,
  delete_charge_points,
  recovery_team,
  group_qr_expired,
  set_group_qr_expired,
  stage_list,
  save_stage,
  delete_stage,
  publish_stage,
  settle_stage,
  stage_group_list,
  session_add_group_league,
  session_add_group_s2,
  update_sect_status_s2,
  submit_sect_update_s2,
  stage_group_list_league,
  stage_groups,
  stage_group_team,
  stage_switch_group,
  session_switch_group,
  stage_session_list,
  stage_set_pattern,
  stage_session_result,
  stage_set_session_result,
  match_banners,
  match_save_banner,
  match_delete_banner,
  stage_team_rank,
  person_team_rank,
  stage_change_team_rank,
  person_change_team_sort,
  person_change_team_rank,
  stage_change_team_sort,
  publish_rank,
  stage_change_s2_rank,
  member_black_uid,
  stage_escort_sects,
  saveReplay,
  getReplay,
  teams1,
  charge_points_info,
  session_add_group,
  match_season_list,
  save_match_season,
  delete_match_season,
  season_group_list,
  match_season_all,
  match_season_all_s3,
  save_season_group,
  delete_season_group,
  saveReplayPic,
  member_team_wdl,
  stage_switch_group_s2,
  update_charge_sect,
  update_charge_result,
  releaseCourse,
  s4_save_stage,
  s4_stage_list,
  s4_settle_stage,
  s4_season_group_list,
  s4_match_team_all,
  s4_match_season_all,
  s4_publish_stage,
  s4_delete_stage,
  s4_update_session_player,
  match_s4: {
    ...match_s4,
  },
};
